<template>
  <div class="ver-container">
    <topMb class="is-mb" />
    <div v-if="lan =='en'" class="ver-box2">
      <div class="ver-image">
        <el-image :src="banner_eng" class="ver-image" />
      </div>
      <div class="ver-content-box">
        <div class="content-title">
          How to Use Coupon
        </div>
        <p class="content-text">1. This is valid and available for download until December 31st, 2022.</p>
        <p class="content-text">2. Enjoy unlimited use within the validity period.</p>
        <p class="content-text">3. Please inform restaurant staff of the coupon upon arrival.</p>
        <p class="content-text">4. This coupon cannot be used with discounts from other websites.</p>
        <p class="content-text">5. Be sure to click the URL link for the coupon before checkout.</p>
        <p class="content-text">6. Please proceed in a network connection environment.</p>
        <p class="content-text">7. Only one coupon can be used per transaction.</p>
        <!-- <p class="content-text">8. Please use the coupon with a stable Internet connection. (It cannot be used without Internet connection)</p>
          <p class="content-text">9. The coupon can only be used once for a single checkout.</p>
          <p class="content-text">10. Consumption less than 10,000 yen, you can get 5%~10% off discount or some special offer provided by the restaurant.Please refer to the official website for restaurant's detailed information. <a href="https://www.tripellet.com" target="_blank">https://www.tripellet.com</a></p> -->
        <br />
        <a href="/restaurant_coupon_en.pdf" target="_blank">
          <div class="content-button">
            Download Coupon
          </div>
        </a>
      </div>
    </div>
    <div v-if="lan =='ja'" class="ver-box">
      <div class="ver-image">
        <el-image :src="banner_jp" class="ver-image" />
      </div>
      <div class="ver-content-box">
        <div class="content-title">
          クーポンの使用方法
        </div>
        <p class="content-text">1.本クーポンの有効期間＆最終ダウンロードできる期間：2022年12月31日まで。</p>
        <p class="content-text">2.有効期間内に使用回数の制限はありません。</p>
        <p class="content-text">3.ご来店の際に、スタッフにクーポン使用をお伝えください。</p>
        <p class="content-text">4.本クーポンは他のサイトの特典と併⽤できません。</p>
        <p class="content-text">5.お会計の前に､必ずURLを開いてください。</p>
        <p class="content-text">6.オフラインでの利用はできません。Wi-Fiや通信が可能な状態でご利用ください。</p>
        <p class="content-text">7.1会計で1回のみ使⽤できます。</p>
        <!-- <p class="content-text">8.オフラインでの利用はできません。Wi-Fiや通信が可能な状態でご利用ください。</p>
          <p class="content-text">9.1会計で1回のみ使⽤できます。</p>
          <p class="content-text">10.食事代金１万円未満の場合、5~10%OFFや他の特典がありますので、詳しくホームページにてご確認ください。<a href="https://www.tripellet.com" target="_blank">https://www.tripellet.com</a></p> -->
        <br />
        <a href="/restaurant_coupon_jp.pdf" target="_blank">
          <div class="content-button">
            クーポン ダウンロード
          </div>
        </a>
      </div>
    </div>
    <div v-if="lan =='zh'" class="ver-box">
      <div class="ver-image">
        <el-image :src="banner_zh" class="ver-image" />
      </div>
      <div class="ver-content-box">
        <div class="content-title">
          优惠券使用方法
        </div>
        <p class="content-text">1.此优惠券有效期限 & 最终可下载期限为2022年12月31日止。</p>
        <p class="content-text">2.有效期限内，无使用次数限制。</p>
        <p class="content-text">3.莅临餐厅时，请告知店员欲使用此优惠券。</p>
        <p class="content-text">4.此优惠券不得与其他网站特典合併使用。</p>
        <p class="content-text">5.结帐前请务必先开启优惠券中的链接。</p>
        <p class="content-text">6.请于网路连线环境下使用。</p>
        <p class="content-text">7.每次结帐，只能使用一张优惠券。</p>
        <!-- <p class="content-text">8.オフラインでの利用はできません。Wi-Fiや通信が可能な状態でご利用ください。</p>
          <p class="content-text">9.1会計で1回のみ使⽤できます。</p>
          <p class="content-text">10.食事代金１万円未満の場合、5~10%OFFや他の特典がありますので、詳しくホームページにてご確認ください。<a href="https://www.tripellet.com" target="_blank">https://www.tripellet.com</a></p> -->
        <br />
        <a href="/restaurant_coupon_sc.pdf" target="_blank">
          <div class="content-button">
            优惠券下载
          </div>
        </a>
      </div>
    </div>
    <div v-if="lan =='zh_tw'" class="ver-box">
      <div class="ver-image">
        <el-image :src="banner_zh_tw" class="ver-image" />
      </div>
      <div class="ver-content-box">
        <div class="content-title">
          優惠券使用方法
        </div>
        <p class="content-text">1.此優惠券有效期限 & 最終可下載期限為2022年12月31日止。</p>
        <p class="content-text">2.有效期限內，無使用次數限制。</p>
        <p class="content-text">3.蒞臨餐廳時，請告知店員欲使用此優惠券。</p>
        <p class="content-text">4.此優惠券不得與其他網站特典合併使用。</p>
        <p class="content-text">5.結帳前請務必先開啟優惠券中的連結。</p>
        <p class="content-text">6.請於網路連線環境下使用。</p>
        <p class="content-text">7.每次結帳，只能使用一張優惠券。</p>
        <!-- <p class="content-text">8.オフラインでの利用はできません。Wi-Fiや通信が可能な状態でご利用ください。</p>
          <p class="content-text">9.1会計で1回のみ使⽤できます。</p>
          <p class="content-text">10.食事代金１万円未満の場合、5~10%OFFや他の特典がありますので、詳しくホームページにてご確認ください。<a href="https://www.tripellet.com" target="_blank">https://www.tripellet.com</a></p> -->
        <br />
        <a href="/restaurant_coupon_tc.pdf" target="_blank">
          <div class="content-button">
            優惠券下載
          </div>
        </a>
      </div>
    </div>
    <div v-if="lan =='ko'" class="ver-box">
      <div class="ver-image">
        <el-image :src="banner_kr" class="ver-image" />
      </div>
      <div class="ver-content-box">
        <div class="content-title">
          쿠폰 사용방법
        </div>
        <p class="content-text">1.해당 쿠폰의 유효기간&다운로드 가능기간 : 2022년12월31일까지.</p>
        <p class="content-text">2.유효기간 내에는 사용횟수에 제한이 없습니다.</p>
        <p class="content-text">3.레스토랑 방문시에는 사전에 직원에게 쿠폰사용을 알려주세요.</p>
        <p class="content-text">4.해당 쿠폰은 다른 사이트의 특전과 함께 사용할 수 없습니다.</p>
        <p class="content-text">5.계산 전에 반드시 URL을 열어주세요.</p>
        <p class="content-text">6.오프라인 상태에서는 이용하실 수 없습니다. 와이파이 및 네트워크 환경이 원활한 상황에서 이용해 주세요.</p>
        <p class="content-text">7.1번의 계산시, 쿠폰은 1회만 사용가능합니다.</p>
        <!-- <p class="content-text">8.オフラインでの利用はできません。Wi-Fiや通信が可能な状態でご利用ください。</p>
          <p class="content-text">9.1会計で1回のみ使⽤できます。</p>
          <p class="content-text">10.食事代金１万円未満の場合、5~10%OFFや他の特典がありますので、詳しくホームページにてご確認ください。<a href="https://www.tripellet.com" target="_blank">https://www.tripellet.com</a></p> -->
        <br />
        <a href="/restaurant_coupon_kr.pdf" target="_blank">
          <div class="content-button">
            쿠폰 다운로드
          </div>
        </a>
      </div>
    </div>
    <bottomMb class="is-mb footer" />
  </div>
</template>

<script>
import bottomMb from '@/components/bottom-moblie'
import topMb from '@/components/top-moblie'
export default {
  data() {
    return {
      banner_eng: require('@/assets/images/ver/EN.png'),
      banner_jp: require('@/assets/images/ver/JP.png'),
      banner_zh: require('@/assets/images/ver/SC.png'),
      banner_zh_tw: require('@/assets/images/ver/TC.png'),
      banner_kr: require('@/assets/images/ver/KR.png'),
    }
  },
  components: {
    topMb,
    bottomMb,
  },
  computed: {
    lan() {
      return localStorage.getItem('locale')
    },
  },
  methods: {},
}
</script>
<style lang='scss' scoped>
.ver-container {
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  min-height: 650px;
  background: rgb(242, 249, 255);
}
.ver-box {
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  min-height: 760px;
  background: #fff;
  border-radius: 20px;
  -moz-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  .ver-image {
    border-radius: 15px;
    width: 100%;
  }
  .ver-content-box {
    margin-top: 20px;
    .content-title {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
      color: #1a5fac;
    }
    .content-text {
      line-height: 22px;
      font-size: 16px;
      color: #1a5fac;
      font-weight: 400;
    }
    .content-button {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-radius: 30px;
      font-size: 20px;
      font-weight: 700;
      background: rgba(26, 95, 172, 1);
      color: #fff;
    }
  }
}
.ver-box2 {
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  min-height: 1000px;
  background: #fff;
  border-radius: 20px;
  -moz-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  .ver-image {
    border-radius: 15px;
    width: 100%;
  }
  .ver-content-box {
    margin-top: 20px;
    .content-title {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
      color: #1a5fac;
    }
    .content-text {
      line-height: 22px;
      font-size: 16px;
      color: #1a5fac;
      font-weight: 400;
    }
    .content-button {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-radius: 30px;
      font-size: 20px;
      font-weight: 700;
      background: rgba(26, 95, 172, 1);
      color: #fff;
    }
  }
}
</style>
